<template>
  <b-container fluid>

    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Conversions</h1>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="8">
        <DateRangePicker :from="fromDate" :to="toDate" @input="input => { this.fromDate = input.date1; this.toDate = input.date2; this.doFetchStatistics() }"></DateRangePicker>
      </b-col>
    </b-row>

    
    <b-row class="mt-0 mt-md-4">
      <b-col cols="12">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <b-table-simple hover class="mb-0">
            <b-thead>
              <b-tr>
                <b-th>Source</b-th>
                <b-th>Month 1</b-th>
                <b-th>Month 2</b-th>
                <b-th>Month 3</b-th>
                <b-th>Month 4</b-th>
                <b-th>Month 5</b-th>
                <b-th>Month 6+</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!isLoading && computedRows.length">
              <b-tr v-for="conversionType in computedRows" :key="conversionType">
                <b-td>
                  {{ conversionType }}
                </b-td>
                <b-td>{{ single[conversionType].month_1 }}%</b-td>
                <b-td>{{ single[conversionType].month_2 }}%</b-td>
                <b-td>{{ single[conversionType].month_3 }}%</b-td>
                <b-td>{{ single[conversionType].month_4 }}%</b-td>
                <b-td>{{ single[conversionType].month_5 }}%</b-td>
                <b-td>{{ single[conversionType].month_6_plus }}%</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="!isLoading && !computedRows.length">
              <b-tr>
                <b-td colspan="7" class="text-center">No Data.</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="isLoading">
              <b-tr>
                <b-td colspan="7" class="text-center">
                  <b-spinner variant="primary"></b-spinner>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import DateRangePicker from '../../components/DateRangePicker'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import numberMixin from '../../mixins/numberMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin, numberMixin],
  data: () => ({
    fromDate: null,
    toDate: null,
  }),
  created () {
    this.fromDate = this.moment().subtract('month', 1).toDate()
    this.toDate = this.moment().toDate()
    this.doFetchStatistics()
  },
  components: {
    FriendlyDate,
    DateRangePicker
  },
  computed: {
    ...mapGetters('conversions', ['single', 'isLoading']),
    computedRows() {
      return Object.keys(this.single).filter(key => {
        return Boolean(this.single[key]);
      });
    },
  },
  methods: {
    ...mapActions('conversions', ['fetchStatistics']),

    doFetchStatistics() {
      this.fetchStatistics({
        fromDate: this.moment(this.fromDate).format('DD/MM/YYYY'),
        toDate: this.moment(this.toDate).format('DD/MM/YYYY'),
      })
    },

    conversionSource(type) {
      switch(type) {
        case 'All': return 'All'; break;
        case 'Google': return 'Google'; break;
        case 'Facebook': return 'Facebook'; break;
      }
    }
  },
}
</script>

<style lang="scss">

.tabs {

  .card-header {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .tab-content {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: unset;
  }

}



</style>
